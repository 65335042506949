import React, { useEffect, useState } from "react";
import { Select, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useDocumentContext } from "../../../contexts/DocumentContext";
import { documentFilters, documentViews } from "../../../constants";
import {
  getAllCollectionsRequest,
  setCollections,
} from "../../../store/actions/collections";
import { useReload } from "../../../hooks/useReload";
import ChevronDown from "../../../assets/svgs/entities/ChevronDown.svg";

const { Option } = Select;
const collectionData = {
  "Imported by": ["All", "Me"],
  globalAccess: ["All", "Private", "Organisation"],
};

/**
 * Filters - Collections, Imported by, and Access
 *
 * @function
 *
 * @returns React JSX
 */
export const Filters = () => {
  const { documentData, setDocumentData } = useDocumentContext();
  const userDetails = useSelector(({ user }) => user.details);
  const [importedBy, setImportedBy] = useState("All");

  const dispatch = useDispatch();
  const { collectionList, getAllCollections: gac } = useSelector(
    ({ collections }) => collections
  );
  useReload(gac.result, () => dispatch(setCollections(gac.result.data)));
  useEffect(() => dispatch(getAllCollectionsRequest()), []);

  // const handleCollectionChange = (value) => {
  //   setDocumentData((documentData) => ({
  //     ...documentData,
  //     [documentFilters.COLLECTION]: value,
  //   }));
  // };

  const onImportdByChange = (value) => {
    setImportedBy(value);
    setDocumentData((documentData) => ({
      ...documentData,
      [documentFilters.IMPORTED_BY]: value === "Me" ? userDetails?.id : "",
    }));
  };

  const handleAccessChange = (value) => {
    if (value === "Private") {
      setDocumentData((prevState) => ({
        ...prevState,
        [documentFilters.ACCESS]: "private",
      }));
    } else if (value === "Organisation") {
      setDocumentData((prevState) => ({
        ...prevState,
        [documentFilters.ACCESS]: "organisation",
      }));
    } else if (value === "All") {
      setDocumentData((prevState) => ({
        ...prevState,
        [documentFilters.ACCESS]: "",
      }));
    }
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOwnerVisible, setisOwnerVisible] = useState(false);
  const [isAccessVisible, setisAccessVisible] = useState(false);

  const handleBucketChange = (checkedValues) => {
    setDocumentData((documentData) => ({
      ...documentData,
      [documentFilters.COLLECTION]: checkedValues,
    }));
  };

  const handleDetailsChange = (e) => {
    setDocumentData((documentData) => ({
      ...documentData,
      [documentViews.CARD_DETAILS]: e.target.checked,
    }));
  };

  return (
    <div className="filter">
      <div>
        <Select
          value={
            <>
              Buckets
              <div className="suffix-icon-all">
                {documentData[documentFilters.COLLECTION]
                  ? collectionList.find(
                      (item) =>
                        item?.id == documentData[documentFilters.COLLECTION]
                    )?.title
                  : "All"}
              </div>
            </>
          }
          onChange={handleBucketChange}
          className="filter-selector"
          suffixIcon={
            <img
              src={ChevronDown}
              alt="down-arrow"
              className={isModalVisible ? "rotate-180" : ""}
            />
          }
          dropdownClassName="filter-selector-options"
          onDropdownVisibleChange={setIsModalVisible}
          virtual={false}
        >
          <Option key={""} className={`filter-selector-options-item All`}>
            <span className={`All`}>All</span>
          </Option>

          {collectionList.map(({ id, title }) => (
            <Option
              key={id}
              className={`filter-selector-options-item ${title}`}
            >
              <span className={`${title}`}>{title}</span>
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <Select
          value={
            <>
              Owner
              <div className="suffix-icon-all">
                {documentData[documentFilters.IMPORTED_BY] && "Me"
                  ? documentData[documentFilters.IMPORTED_BY] && "Me"
                  : "All"}
              </div>
            </>
          }
          onChange={onImportdByChange}
          className="filter-selector"
          suffixIcon={
            <img
              src={ChevronDown}
              alt="down-arrow"
              className={isOwnerVisible ? "rotate-180" : ""}
            />
          }
          dropdownClassName="filter-selector-options"
          onDropdownVisibleChange={setisOwnerVisible}
        >
          {collectionData["Imported by"].map((importedBy) => (
            <Option
              key={importedBy}
              className={`filter-selector-options-item ${importedBy}`}
            >
              <span className={`${importedBy}`}>{importedBy}</span>
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <Select
          value={
            <>
              Access
              <div className="suffix-icon-all">
                {documentData[documentFilters.ACCESS]
                  ? documentData[documentFilters.ACCESS]
                      ?.charAt(0)
                      .toUpperCase() +
                    documentData[documentFilters.ACCESS]?.slice(1)
                  : "All"}
              </div>
            </>
          }
          onChange={handleAccessChange}
          className="filter-selector"
          suffixIcon={
            <img
              src={ChevronDown}
              alt="down-arrow"
              className={isAccessVisible ? "rotate-180" : ""}
            />
          }
          dropdownClassName="filter-selector-options"
          onDropdownVisibleChange={setisAccessVisible}
        >
          {collectionData.globalAccess.map((access) => (
            <Option key={access} className={`filter-selector-options-item `}>
              <span className={`${access}`}>{access}</span>
            </Option>
          ))}
        </Select>
      </div>
      <div>
        Show Card Details{" "}
        <Checkbox
          checked={documentData[documentViews.CARD_DETAILS]}
          onChange={handleDetailsChange}
        />
      </div>
    </div>
  );
};
