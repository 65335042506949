const successMessages = {
  entity_accepted: "Entity accepted",
  entity_rejected: "Entity rejected",
  entity_added: "Entity added",
  entity_added_accepted: "Entity added and accepted",
  entity_rejected_rule: "Entity rejected and added to rule",
  document_removed: "Document removed",
  url_queued: "URL document added to queue",
  accepted_entity_count: "Accepted ::count:: entities",
  rejected_entity_count: "Rejected ::count:: entities",
  change_to_organisation:
    "Document successfully changed to organisation access level",
};

export default successMessages;
