import { useCallback, useRef } from "react";
import { useVirtual } from "react-virtual";
import "../../../assets/styles/scroll-virtualizer.scss";

const ScrollVirtualizer = ({
  children,
  documents,
  nextPage,
  hasMore,
  isLoading,
  currentPage,
  pageSize,
  height = 130,
}) => {
  const parentRef = useRef();

  const observer = useRef();
  const lastDocumentElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const lastDocumentIndex = currentPage * pageSize - 1;
          if (documents.length - 1 === lastDocumentIndex) {
            nextPage();
            currentPage += 1;
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  const rowVirtualizer = useVirtual({
    size: documents.length,
    parentRef,
    estimateSize: useCallback(() => height, [height]),
    overscan: 5,
  });

  return (
    <div ref={parentRef} className="list-virtualizer">
      <div
        style={{
          height: `${rowVirtualizer.totalSize}px`,
          position: "relative",
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow, index) => (
          <div
            ref={
              documents.length === virtualRow.index + 1
                ? lastDocumentElementRef
                : undefined
            }
            key={index}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
              padding: 1,
            }}
          >
            {children(virtualRow)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollVirtualizer;
