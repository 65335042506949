import _ from "lodash";
import axios from "axios";
import { Modal } from "antd";
import { documentStatus } from "../../constants";
import events from "../../constants/analyticEvents";
import api from "../../constants/api";
import routes from "../../constants/routes";
import Mixpanel from "../services/Mixpanel";
import { confirmAction } from "../validators.js/confirmAction";
import Auth from "../services/Auth";
import messageBox from "../services/MessageBox";
import {
  completeToForReviewStatusWarning,
  completeToReviewingStatusConfirmation,
  markCompleteStatusConfirmation,
} from "../messages/popup";

export const handleDeleteDocument = (id, updateRequest) => {
  updateRequest(`${api.DOCUMENT_API}/${id}`, {
    delete: true,
  });
};

export const handleChangeToOrganisation = (id, updateRequest) => {
  updateRequest(`${api.DOCUMENT_API}/${id}/access_level/organisation`);
};

export const handleDocStatusUpdate = (
  id,
  newStatus,
  currentStatus = "",
  updateRequest,
  cancelAction = null
) => {
  const sendRequest = () =>
    updateRequest(`${api.DOCUMENT_API}/${id}`, {
      status: newStatus,
    });
  if (
    currentStatus === documentStatus.COMPLETED &&
    newStatus === documentStatus.FOR_REVIEW
  ) {
    Modal.warn(completeToForReviewStatusWarning());
    if (cancelAction) cancelAction();
    return;
  }

  if (
    currentStatus === documentStatus.COMPLETED &&
    newStatus === documentStatus.REVIEWING
  ) {
    Modal.confirm(
      completeToReviewingStatusConfirmation(sendRequest, cancelAction)
    );
    return;
  }

  if (newStatus === documentStatus.COMPLETED) {
    const handleConfirm = () => {
      sendRequest();
      Mixpanel.track(events.MARK_DOCUMENT_COMPLETED, { documentId: id });
    };
    Modal.confirm(markCompleteStatusConfirmation(handleConfirm, cancelAction));

    return;
  }

  sendRequest();
};

export const handleDocumentStatusUpdate = (id, status, updateRequest) => {
  const sendRequest = () =>
    updateRequest(`${api.DOCUMENT_API}/${id}`, {
      status,
    });
  if (status === documentStatus.COMPLETED) {
    const msg = `Once marked as completed you cannot change this document status`;
    confirmAction(msg, () => {
      sendRequest();
      Mixpanel.track(events.MARK_DOCUMENT_COMPLETED, { documentId: id });
    });
    return;
  }

  sendRequest();
};

export const handleDrop = (index, source, target, setData) => {
  setData((data) => {
    const sourceList = _.cloneDeep(data[source]) || [];
    const targetList = _.cloneDeep(data[target]) || [];
    const doc = sourceList.splice(index, 1)[0];
    if (doc) {
      doc.status = target;
      doc.prevStatus = source;
      doc.pending = true;
      targetList.unshift(doc);
    }

    return {
      ...data,
      [source]: sourceList,
      [target]: targetList,
    };
  });
};

export const handleClickAnalyse = ({ document_id }, history) => {
  history.push(
    `${routes.DOCUMENT_ANALYSIS.replace(":documentId", document_id)}`
  );
};

export const copyTextClipBoard = async (
  text,
  inputId = "copyInput",
  callBack = null
) => {
  try {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(text);
    } else if (window.clipboardData && window.clipboardData.setData) {
      window.clipboardData.setData("Text", text);
    } else {
      const copyInput = document.querySelector(`#${inputId}`);
      if (!copyInput) throw new Error("Copied failed");
      if (text) {
        if (copyInput.tagName === "input" || copyInput.tagName === "textarea") {
          copyInput.value = text;
        } else {
          copyInput.textContent = text;
        }
      }
      /* Select the text field */
      copyInput.select();
      copyInput.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
    }
    if (callBack && typeof callBack === "function") callBack();
  } catch (error) {
    console.log(error);
  }
};

export const maskText = (text, options = {}) => {
  let maskedText = "";
  const { mask = "x", showPrefix = 0, showSuffix = 0 } = options;
  if (text && typeof text === "string" && typeof mask === "string") {
    const repeatCount = text.length - showPrefix - showSuffix;
    const masks = repeatCount > 0 ? mask.repeat(repeatCount) : "";
    maskedText = `${text.slice(0, showPrefix)}${masks}${
      showSuffix ? text.slice(-showSuffix) : ""
    }`;
  }

  return maskedText;
};

export const collapseIcon = (CloseIcon, OpenIcon) => {
  return ({ isActive }) => (isActive ? <OpenIcon /> : <CloseIcon />);
};

export const handleProgressEvent = (setProgress) => (event) => {
  const { loaded, total } = event;
  const percent = Math.round((loaded / total) * 100);
  setProgress({ loaded, total, percent });
};

export const getQueryParams = (url) => {
  const params = {};
  if (typeof url !== "string") return params;
  const queryStr = url.split("?")[1];
  if (!queryStr) return params;
  const queryList = queryStr.split("&");
  queryList.forEach((q) => {
    const p = q.split("=");
    params[p[0]] = p[1];
  });

  return params;
};

export const browserDownload = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName || "download");
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
};

export const handleDownload = (apiUrl, filename, token) => {
  const datetimeStr = new Date().toISOString();
  const fn = `${filename}`.replace("::datetime::", datetimeStr);
  return () => {
    axios
      .get(apiUrl, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        browserDownload(url, fn);
      })
      .catch(() => {
        messageBox.showError("Unable to download file");
      });
  };
};

export const preLoadImages = async (imageUrls) => {
  try {
    const promises = imageUrls?.map(
      (src) =>
        new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = resolve;
          image.onerror = reject;
          image.src = src;
        })
    );

    await Promise.allSettled(promises);
  } catch (error) {
    console.log(error);
  }
};

export const toggleState = (setState) => {
  setState((state) => !state);
};

export const getConfidenceScore = (confidence_score) => {
  if (confidence_score === null || confidence_score === undefined) {
    return {
      color: "transparent",
      text: "",
    };
  }
  if (confidence_score > 0 && confidence_score <= 80) {
    return {
      color: "red",
      text: "Probable",
    };
  }
  if (confidence_score > 80 && confidence_score <= 90) {
    return {
      color: "orange",
      text: "Very likely",
    };
  }
  if (confidence_score > 90) {
    return {
      color: "green",
      text: "Almost certain",
    };
  }
};

export const getSelectInputProps = (defaultLabel, data = {}) => {
  return {
    showSearch: false,
    allowClear: false,
    style: { width: "150px" },
    className: "mr-15",
    defaultValue: `${defaultLabel}: All`,
    options: [
      { label: `${defaultLabel}: All`, value: "" },
      ...Object.values(data).map(({ value, label }) => ({ value, label })),
    ],
  };
};

export const secondsToHMS = (seconds) => {
  let hrs = 0;
  let mins = 0;
  let sec = seconds;

  if (sec >= 3600) {
    hrs = Math.floor(sec / 3600);
    sec %= 3600;
  }

  if (sec >= 60) {
    mins = Math.floor(sec / 60);
    sec %= 60;
  }

  return [hrs, mins, sec];
};

export const HMSToString = (HMS = [0, 0, 0]) => {
  const [hours, minutes, seconds] = HMS;
  let timeString = "";

  if (hours) timeString += hours > 1 ? `${hours} hours ` : `${hours} hour `;
  if (minutes)
    timeString += minutes > 1 ? `${minutes} minutes ` : `${minutes} minute `;
  if (seconds)
    timeString += seconds > 1 ? `${seconds} seconds ` : `${seconds} second`;

  return timeString;
};

export const performanceDashboardSupported = () => {
  const pdRealms = window._env_.PERFORMANCE_DASHBOARD_REALMS;
  let performanceRealms = [];
  if (pdRealms) performanceRealms = pdRealms.split(",");
  return performanceRealms.includes(Auth.getCurrentRealm());
};

export const capitalizeFirstLetter = (text) => {
  if (!text) return ""; // If the text is empty or undefined, return an empty string
  return text.charAt(0).toUpperCase() + text.slice(1);
};
