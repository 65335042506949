import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import { useDrag } from "react-dnd";
import { dragItems, documentStatus, documentViews } from "../../../constants";
import useAxios from "../../../hooks/useAxios";
import { useColumnsReload, useReload } from "../../../hooks/useReload";
import DisplayTip from "../../common/widgets/DisplayTip";
import routes from "../../../constants/routes";
import { useDocumentContext } from "../../../contexts/DocumentContext";
import {
  useMessageBoxError,
  useMessageBoxSuccess,
} from "../../../hooks/useMessageBox";
import successMessages from "../../../utils/messages/successMessages";
import { MoreMenu, MoreMenu1 } from "./DocumentCardActions";
import { useState } from "react";
import moreHorizontal from "../../../assets/svgs/entities/more-horizontal.svg";
import { capitalizeFirstLetter } from "../../../utils/helpers";

const { COMPLETED } = documentStatus;

const DocumentCard = ({ document, index }) => {
  const { setDocumentData, documentData } = useDocumentContext();
  const { document_id, status } = document;
  const [{ result: delRes, error: delErr }, delUpdateReq] = useAxios(
    null,
    "PUT"
  );
  const [{ result: orgAccessRes, error: accessErr }, orgAccessReq] = useAxios(
    null,
    "PUT"
  );

  useReload(delRes, () => {
    setDocumentData((prevState) => {
      const newState = { ...prevState };
      newState[status] = newState[status].filter(
        (doc) => doc.document_id !== document_id
      );
      return newState;
    });
  });

  useColumnsReload(delRes, [status]);
  useColumnsReload(orgAccessRes, [status]);

  const errMsg = delErr && delErr.details ? delErr.details.status : null;
  useMessageBoxError(delErr, errMsg);
  useMessageBoxError(accessErr);

  useMessageBoxSuccess(delRes, successMessages.document_removed);
  useMessageBoxSuccess(orgAccessRes);

  const isCompleted = status === COMPLETED;

  const [{ isDragging }, drag] = useDrag({
    item: { type: dragItems.CARD, document, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: () => !document.pending,
  });

  let style = {};
  if (isDragging) style = { cursor: "grabbing" };
  if (document.pending) style["backgroundColor"] = "#efefef";

  const analysisUrl = routes.DOCUMENT_ANALYSIS.replace(
    ":documentId",
    document_id
  );
  const [dropdownVisible, setDropDownVisible] = useState(false);

  const closeDropdown = () => {
    setDropDownVisible((prev) => !prev);
  };

  let with_details = "";
  if (documentData[documentViews.CARD_DETAILS]) {
    with_details = "document-card-with-details";
  }

  return (
    <Link to={analysisUrl} style={{ textDecoration: "none" }}>
      <div
        className={`document-card ${with_details}`}
        style={style}
        ref={drag}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="document-card-header">
          <div className="document-card-title cursor-pointer">
            <DisplayTip title={document.title}>{document.title}</DisplayTip>
          </div>
          <div className="justify-end cursor-pointer">
            <Dropdown
              overlay={
                isCompleted ? (
                  <MoreMenu
                    document={document}
                    delUpdateReq={delUpdateReq}
                    orgAccessReq={orgAccessReq}
                    closeDropdown={closeDropdown}
                  />
                ) : (
                  <MoreMenu1
                    document={document}
                    delUpdateReq={delUpdateReq}
                    orgAccessReq={orgAccessReq}
                    closeDropdown={closeDropdown}
                  />
                )
              }
              trigger={["click"]}
              visible={dropdownVisible}
              onVisibleChange={() => setDropDownVisible(!dropdownVisible)}
            >
              <img
                src={moreHorizontal}
                alt="ellipse-outlined"
                className="more-horizontal"
              />
            </Dropdown>
          </div>
        </div>

        <div>
          <p className="text-ellipsis cursor-pointer f-gray-500 my-0 py-2">
            <DisplayTip title={document.quick_summary} placement="bottomLeft">
              {document.quick_summary}
            </DisplayTip>
          </p>
        </div>

        <div className="document-card-actions">
          <div className="document-entities-extracted">
            <span className="entities-count">ENTITIES EXTRACTED</span>
            <span className="entities-count-values">
              {document.entity_count}
            </span>
          </div>
          <div className="document-relatioships">
            <span className="entities-count">RELATIONSHIPS IDENTIFIED</span>
            <span className="entities-count-values">
              {document.relationship_count}
            </span>
          </div>
        </div>

        {documentData[documentViews.CARD_DETAILS] && (
          <div className="">
            {document.uploader_name && (
              <div className="">
                <span className="doc-card-detail">UPLOADED BY</span>
                <span className="doc-card-detail-value">
                  {document.uploader_name}
                </span>
              </div>
            )}
            {document.last_editor_name && (
              <div className="">
                <span className="doc-card-detail">LAST UPDATED BY</span>
                <span className="doc-card-detail-value">
                  {document.last_editor_name}
                </span>
              </div>
            )}
            {document.created_at && (
              <div className="">
                <span className="doc-card-detail">UPLOADED ON</span>
                <span className="doc-card-detail-value">
                  {document.created_at}
                </span>
              </div>
            )}
            {document.last_updated_on && (
              <div className="">
                <span className="doc-card-detail">LAST UPDATED ON</span>
                <span className="doc-card-detail-value">
                  {document.last_updated_on}
                </span>
              </div>
            )}
            {document.status_updated_on && (
              <div className="">
                <span className="doc-card-detail">DATE IN COLUMN</span>
                <span className="doc-card-detail-value">
                  {document.status_updated_on}
                </span>
              </div>
            )}
            {document.source_type && (
              <div className="">
                <span className="doc-card-detail">TYPE</span>
                <span className="doc-card-detail-value">
                  {`${document.source_type}`.toUpperCase()}
                </span>
              </div>
            )}
            {document.access_level && (
              <div className="">
                <span className="doc-card-detail">PRIVACY</span>
                <span className="doc-card-detail-value">
                  {capitalizeFirstLetter(document.access_level)}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </Link>
  );
};

export default DocumentCard;
